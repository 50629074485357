import React          from "react";
import PropTypes 			from 'prop-types';
import styled         from 'styled-components';
import { Link }       from 'gatsby';
import { Colors } 		from '../Layouts/Colors';


const propTypes = {
	color  : PropTypes.string,
	color1 : PropTypes.string,
	color2 : PropTypes.string,
},
defaultProps = {
	color  : Colors.Black,
	color1  : Colors.Black,
	color2  : Colors.BabyBlue
}

const Wrapper = styled.div`
  font-family: sofia-pro, sans-serif;
	font-size: 16px;
	letter-spacing: 1.5px;
	color: ${({ inverted }) => inverted ? Colors.White : Colors.Black };
	font-weight: bold;
	padding: 0;
	text-transform: uppercase;
	transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
	display: flex;
	justify-content: center;
	flex-direction: ${({ down }) => down ? 'column' : 'row'};

	::selection {
	  background: #000;
	  color: white;
	}

	&:hover {
		color: ${({ inverted }) => inverted ? '#a8a8a8' : '#f2f2f2' };
		letter-spacing: 1.85px;
	}

  @media only screen and (max-width: 780px) {
		font-size: 16px;
  	letter-spacing: 3px;
  }
`;

const ArrowSpan = styled.span`
	font-size: 18px;
	color: white;

	@media only screen and (max-width: 780px) {
		font-size: 24px;
		margin-top: ${({ down }) => down ? '16px' : null};
	}
`;

const ExternalLink = styled.a`
	color: ${({ inverted }) => inverted ? Colors.White : Colors.Black };
	color: ${({ inverted }) => inverted ? '#a8a8a8' : '#f2f2f2' };

`;

const NewLink = ({ linkTo, down, right, external, href, children, inverted }) => {

	let SwitchLink = external ? ExternalLink : Link;

	return (
		<Wrapper down={down} inverted={inverted}>

      <SwitchLink
        href={href}
        target={external ? "_blank" : null}
        external={external} 
        to={linkTo} 
				>{children} 
			</SwitchLink>
			{down &&
					<ArrowSpan down={down}>↓</ArrowSpan>
				}
				{!down &&
					<ArrowSpan >→</ArrowSpan>
				}
		</Wrapper>
	);
}

NewLink.propTypes 	  = propTypes;
NewLink.defaultProps  = defaultProps;

export default NewLink;