import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header,
				 Body } from './Typography';
import { NewLink } from './Buttons';
import { SiteContent,
				 Colors } from './Layouts';

import logo from '../images/tex-air-gas-logo.svg';

const propTypes = {
},
defaultProps = {
}

const Wrapper = styled.div`
  padding: 40px 0;
	background-color: ${Colors.Primary};
`;

const ContentWrapper = styled.div`
	display: flex;
	width: 50%;
  justify-content: space-between;
	flex-direction: column;

	@media only screen and (max-width: 980px) {
  	flex-direction: column;
	}

	@media only screen and (max-width: 580px) {
		width: 100%;
		margin-bottom: 40px;
	}
`;

const InnerWrapper = styled.div`
  padding: 40px 0;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

	::selection {
	  background: #000;
	  color: black;
	};

  @media only screen and (max-width: 980px) {
	  flex-direction: row;
	}

	@media only screen and (max-width: 580px) {
	  flex-direction: column;
	}

	@media only screen and (max-width: 400px) {

	}
`;

const Input = styled.input`
	width: 100%;
	background-color: ${Colors.Primary};
	color: white;
	border: none;
	border-bottom: .5px solid white;
	margin-bottom: 8px;
`;

const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 8px 40px;
  line-height: 1.2;
  font-family: termina, sans-serif;
  border-radius: 70px;
  background-color: white;
  border: none;
  color: ${Colors.Primary};
  margin-top: 20px;
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  cursor: pointer;
	box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.0);

	&:hover {
		background-color: ${Colors.Primary};
		color: white;
		box-shadow: 0 12px 24px 0px rgba(0, 0, 0, 0.2);
	}
`;

const Form = styled.form`
	margin-top: 40px;
	margin-bottom: 0;
`;

const Label = styled.label`
	opacity: .7;
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class Contact extends React.Component {
	render () {
		return (
      <div id="contact">
			<Wrapper>
				<SiteContent>
	        <img src={logo} alt="Tex Air Gas Co. Logo" style={{ width: '280px' }} />
				</SiteContent>

				<SiteContent column>
					<InnerWrapper>

						<ContentWrapper>
		          <div>
			          <Header h4 color="white">Contact us</Header>
			          <Body color="white">(806) 672-8049</Body>
			          <Body color="white">joe.prowell@texaircorp.com</Body>
		          </div>
		          <div>
			          <Body color="white">(806) 517-9858</Body>
			          <Body color="white">jim.prowell@texaircorp.com</Body>
		          </div>

		          <div>
			          <Body color="white">PO Box 427</Body>
			          <Body color="white">Amarillo, TX 79105</Body>
		          </div>
		          <div>
		          </div>

						</ContentWrapper>

						<ContentWrapper>
							<Header h4 color="white">No time to talk?</Header>
		          <Body color="white">Leave us a message and we’ll get back to you.</Body>
							
							<Form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <Input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <Label>
                    Don’t fill this out:{' '}
                    <Input name="bot-field" onChange={this.handleChange} />
                  </Label>
                </div>

                <div className="field">
                  <Label className="label" htmlFor={'name'}>
                    Name
                  </Label>
                  <div className="control">
                    <Input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <Label className="label" htmlFor={'email'}>
                    Email
                  </Label>
                  <div className="control">
                    <Input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <Label className="label" htmlFor={'phone'}>
                    Phone
                  </Label>
                  <div className="control">
                    <Input
                      className="input"
                      type={'phone'}
                      name={'phone'}
                      onChange={this.handleChange}
                      id={'phone'}
                      required={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <Label className="label" htmlFor={'shipping-destination'}>
                    Shipping Destination
                  </Label>
                  <div className="control">
                    <Input
                      className="input"
                      type={'shipping-destination'}
                      name={'shipping-destination'}
                      onChange={this.handleChange}
                      id={'shipping-destination'}
                      required={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <Label className="label" htmlFor={'quantity'}>
                    Quantity
                  </Label>
                  <div className="control">
                    <Input
                      className="input"
                      type={'quantity'}
                      name={'quantity'}
                      onChange={this.handleChange}
                      id={'quantity'}
                      required={true}
                    />
                  </div>
                </div>


                <div className="field">
                  <Button className="button" type="submit">
                    send
                  </Button>
                </div>
              </Form>

						</ContentWrapper>

					</InnerWrapper>
				</SiteContent>
			</Wrapper>
      </div>
		);
	}
}

Contact.propTypes 	  = propTypes;
Contact.defaultProps  = defaultProps;

export default Contact;