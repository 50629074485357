import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header,
				 Body } from './Typography';
import { NewLink } from './Buttons';
import { SiteContent,
				 Colors } from './Layouts';

const propTypes = {
},
defaultProps = {
}

const Wrapper = styled.div`
	background-color: white;
`;

const ContentWrapper = styled.div`
	display: flex;
	width: 100%;
  justify-content: space-between;
	padding-bottom: 40px;

	@media only screen and (max-width: 980px) {
  	flex-direction: column;
		padding-bottom: 80px;
	}
`;

const ThirdWrapper = styled.div`
	display: flex;
  flex-direction: column;
  width: 30%;
  min-height: 400px;
  padding: 50px 0px;
  margin-left: 1.525%;
  margin-right: 1.525%;
  background-color: white;
  text-align: center;
  align-items: center;

  @media only screen and (max-width: 980px) {
	  width: 80%;
	  margin: 0 auto;
	  padding: 20px 0px 10px 0;
    min-height: 200px;
	}
`;

const Image = styled.div`
	width:  84px;
	height: 84px;
	background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  margin-bottom: 21px;

	@media only screen and (max-width: 980px) {
		margin: 20px;
	}

	@media only screen and (max-width: 580px) {
		margin: 20px;
		margin: 0 auto;
		margin-bottom: 20px;
	}
`;

const InnerWrapper = styled.div`
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

	::selection {
	  background: #000;
	  color: black;
	};

  @media only screen and (max-width: 980px) {
	  flex-direction: row;
	}

	@media only screen and (max-width: 580px) {

	}

	@media only screen and (max-width: 400px) {

	}
`;

const ThreeCol = ({ 
	image1,
	headerTitle1,
	subtitle1, 
	image2, 
	headerTitle2,
	subtitle2, 
	image3,
	headerTitle3,
	subtitle3, 
}) => {
	return (
		<Wrapper>
			<InnerWrapper>
					<ContentWrapper>
						<ThirdWrapper>
							<Image 
								title="Icon of wholesale helium truck from Tex-Air Gas"
								style={{
			          backgroundImage: `url(${!image1 ? null : !!image1.childImageSharp ? image1.childImageSharp.fluid.src : image1})`
			        }}/>
							<Header h5 color={Colors.Primary}>
								{headerTitle1}
				      </Header>
				      <Body>
				      	{subtitle1}
				      </Body>
						</ThirdWrapper>

						<ThirdWrapper>
							<Image 
								title="Icon of dollar sign representing transparent pricing of wholesale helium from Tex-Air gas"
								style={{
			          backgroundImage: `url(${!image2 ? null : !!image2.childImageSharp ? image2.childImageSharp.fluid.src : image2})`
			        }}/>
							<Header h5 color={Colors.Primary}>
								{headerTitle2}
				      </Header>
				      <Body>
				      	{subtitle2}
				      </Body> 
						</ThirdWrapper>

						<ThirdWrapper>
							<Image 
								title="Icon of lightning bolt representing rapid response of Tex-Air Gas, a wholesale helium distributor"
								style={{
			          backgroundImage: `url(${!image3 ? null : !!image3.childImageSharp ? image3.childImageSharp.fluid.src : image3})`
			        }}/>
							<Header h5 color={Colors.Primary}>
								{headerTitle3}
				      </Header>
				      <Body>
				      	{subtitle3}
				      </Body>
						</ThirdWrapper>

					</ContentWrapper>
			</InnerWrapper>
		</Wrapper>
	);
}

ThreeCol.propTypes 	  = propTypes;
ThreeCol.defaultProps  = defaultProps;

export default ThreeCol;