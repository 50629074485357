import * as React from "react";
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Navbar from '../components/Navbar';
import HomePageHero from '../components/HomePageHero';
import { SiteContent,
         TextBlock,
         Colors } from '../components/Layouts';
import { Header,
         Subheader,
         Body } from '../components/Typography';  
import ThreeCol from '../components/ThreeCol';
import Timeline from '../components/Timeline';
import Contact from '../components/Contact';
import { InnovationsSection } from '../components/Sections';
import HeroBGImage from '../images/texAirGas-truck-solo-overlay.jpg';
import InnovBGImage from '../images/texAirGas-david-blaine-balloon.jpg';
import HereditaryImage from '../images/tex-air-gas-JoeJim1984.jpg';
import SDVOSBImage from '../images/SDVOSB-logo.jpg';
import BigJimJr from '../images/tex-air-gas-JimJr.jpg';
import Joe from '../images/tex-air-gas-Joe.jpg';
import MetaImage from '../images/og-image.jpg';

import supplyIcon from '../images/icons/texAirGas-supply-icon.svg';
import pricingIcon from '../images/icons/texAirGas-pricing-icon.svg';
import rapidIcon from '../images/icons/texAirGas-rapid-icon.svg';
import rocketIcon from '../images/icons/texAirGas-rocket-icon.svg';

import { Helmet } from "react-helmet";

import "../components/CSS/layout.css"
// import testData from '../data/data';

const IntroWrapper = styled.div`
  padding: 0 0 60px 0;
`;

const TimelineWrapper = styled.div`
  margin-top: 120px;
  padding-left: 10%;
`;

const HereditaryWrapper = styled.div`
  padding: 60px 0;
`;

const FutureWrapper = styled.div`
  padding: 80px 0;
`;

const HereditaryImg = styled.img`
  width: 350px;
  height: auto;
  transform: rotate(-2deg);
  padding-bottom: 40px;
`;

const SDVSBImage = styled.img`
  width: 225px;
  border-radius: 50%;
  margin-top: -120px;
  margin-bottom: 40px;
  box-shadow: 0px 12px 26px #00000029;
`;

const FamilyWrapper = styled.div`
  background-color: #EFEFEF;
  padding-bottom: 80px;
  text-align: center;
`;

const FamilyImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 62px;
  flex-direction: row;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FamilyImage = styled.img`
  height: auto;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const FamilyColumn = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media only screen and (max-width: 640px) {
    width: 80%;
    margin-bottom: 32px;
  }
`;

const RocketImage = styled.img`
  width:  84px;
  height: 84px;
  margin-bottom: 24px;
`;

// markup
const IndexPage = ( data ) => {

  const {
    hero,
    intro,
    innovation,
    hereditary,
    timeline,
    family,
    future,
    contact
  } = data.data.markdownRemark.frontmatter;

  return (
    <main>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Tex-Air Gas Co | Wholesale Helium & Gas</title>
          <meta name="description" content='Tex-Air Gas is a wholesale helium distributor in Amarillo, TX. As a leader in industrial gas, Tex-Air Gas distributes bulk helium to the continental U.S.' />
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-192192310-1"></script>
          <meta name="google-site-verification" content="p5_MfFPqosATmOrQDIsjsf-2OV8xnXCY7S11DA6duGI" />
          <meta
          property="og:image"
          content={MetaImage}/>

        </Helmet>
      <title>Tex-Air Gas Co., Inc.</title>
      <Navbar/>
      <HomePageHero
        maxWidth
        center
        featuredImage={HeroBGImage}
        headerTitle={hero.title}
        subtitle={hero.body}
        buttonTitle={hero.buttonTitle}
        linkTo='#intro'
      />
      
      <div id="intro">
      <IntroWrapper>
        <SiteContent center>
          <TextBlock
            h2
            center
            headerText={intro.title}
            bodyText={intro.body}
          />
          <ThreeCol
            image1={supplyIcon}
            headerTitle1={intro.headerTitle1}
            subtitle1={intro.subtitle1}
            image2={pricingIcon}
            headerTitle2={intro.headerTitle2}
            subtitle2={intro.subtitle2}
            image3={rapidIcon}
            headerTitle3={intro.headerTitle3}
            subtitle3={intro.subtitle3}
          />
          <TextBlock
            h3
            center
            headerText={intro.ctaTitle}
            bodyText={intro.ctaSubtitle}
            buttonTitle={intro.ctaButtonTitle}
            linkTo='#contact'
          />
        </SiteContent>
      </IntroWrapper>
      </div>

      <InnovationsSection
        center
        featuredImage={InnovBGImage}
        headerTitle={innovation.title}
        subtitle={innovation.body}
        buttonTitle={innovation.buttonTitle}
        linkTo='/contact'
      />

      <HereditaryWrapper>
        <SiteContent column>
          <HereditaryImg 
            src={HereditaryImage} 
            alt="tex-air gas leaders jim and joe prowell stand in front of helium truck in 1980s" 
            title="Tex-Air Gas leaders Jim and Joe Prowell stand in front of helium truck in 1980s"/>
          <TextBlock
            h2
            center
            marginBottom='24px'
            titleColor={Colors.BabyBlue}
            headerText={hereditary.title}
            bodyText={hereditary.body}
            />
        </SiteContent>
      </HereditaryWrapper>

      <TimelineWrapper>
        <TextBlock
          h2
          marginBottom='24px'
          titleColor={Colors.Primary}
          headerText={timeline.title}
          subheader={timeline.subheading}
            />
      </TimelineWrapper>
      <Timeline data={timeline.details}/>

      <FamilyWrapper>
        <SiteContent column>
          <SDVSBImage 
            src={SDVOSBImage} 
            alt="sdvosb icon representing tex-air gas as a service-disabled veteran-owned small business"
            title="SDVOSB icon representing Tex-Air Gas as a service-disabled veteran-owned small business"/>
          <Header 
            h2
            noMargin 
            color={Colors.BabyBlue}>
              {family.title1}
          </Header>
          <Header 
            h2
            noMargin
            color={Colors.Primary}>
            {family.title2}
          </Header>
          <Header 
            h2
            color='#16406C'>
            {family.title3}
          </Header>
          <Subheader h2>{family.subheading}</Subheader>
          <Body center>{family.body}</Body>
          <FamilyImageWrapper>
            <FamilyColumn>
              <FamilyImage 
                src={BigJimJr} 
                alt="tex-air industrial gas leader jim prowell headshot"
                title="Industrial Gas leader Jim Prowell poses for headshot at Tex-Air Gas"/>
              <Header h5 noMargin>Jim Prowell</Header>
              <Body>Executive Vice President</Body>
            </FamilyColumn>

            <FamilyColumn>
              <FamilyImage 
                src={Joe} 
                alt="tex-air industrial gas leader joe prowell headshot"
                title="Industrial Gas leader Joe Prowell poses for headshot at Tex-Air Gas"/>
              <Header h5 noMargin>Joe Prowell</Header>
              <Body>President</Body>
            </FamilyColumn>
          </FamilyImageWrapper>
        </SiteContent>
      </FamilyWrapper>

      <FutureWrapper>
        <SiteContent column>
          <RocketImage 
            src={rocketIcon} 
            alt="icon of rocket ship representing future of industrial gas company, tex-air gas"
            title="Icon of rocket ship taking Tex-Air Gas into the future of the wholesale helium industry"/>
          <TextBlock
            h3
            center
            marginBottom='24px'
            titleColor={Colors.Primary}
            headerText={future.title}
            bodyText={future.body}
            />
        </SiteContent>
      </FutureWrapper>
      <Contact/>
    </main>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        templateKey
        intro {
          body
          buttonTitle
          ctaBody
          ctaButtonTitle
          ctaTitle
          title
          subtitle3
          subtitle2
          subtitle1
          headerTitle3
          headerTitle2
          headerTitle1
        }
        innovation {
          body
          buttonTitle
          title
        }
        hero {
          body
          buttonTitle
          title
        }
        hereditary {
          body
          title
        }
        future {
          body
          title
        }
        family {
          body
          subheading
          title1
          title2
          title3
        }
         timeline {
          details {
            year
            body
          }
          subheading
          title
        }
      }
    }
  }
`