export const Colors = {

Primary	 : '#35699F',
Blue		 : '#35699F',
Yellow 	 : '#FE8F19',
BabyBlue : '#00B5E2',
White 	 : '#FFFFFF',
Black		 : '#000000',
Red			 : '#FF0320',
Orange 	 : '#E08A00',
OffBlack : '#0D1112',
Grey		 : '#707070',
}

export default Colors;