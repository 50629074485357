import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SiteContent,
				 TextBlock } from '../Layouts';

import Video from '../Video';

const propTypes = {
	linkTo: PropTypes.string,
},
defaultProps = {
	linkTo: "#"
}

const Wrapper = styled.div`
`;

const VideoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	background-color: rgba(255, 255, 255, .35);
	padding: 20px;
	margin: 0 auto;
	margin-top: 80px;
	border-radius: 16px;
	backdrop-filter: blur(15px);
	-webkit-backdrop-filter: blur(15px);
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);

	@media only screen and (max-width: 780px) {
	flex-direction: column;
	width: 90%;
	}
`;

const HeaderWrapper = styled.div`
	padding: 80px 0;
  min-height: 800px;
  background-color: ${({ inverted }) => inverted ? 'black' : '#f2f2f2' };
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

	::selection {
	  background: #000;
	  color: #f2f2f2;
	};

  @media only screen and (max-width: 780px) {
	}

	@media only screen and (max-width: 580px) {
	}

	@media only screen and (max-width: 400px) {
	}
`;

const InnovationsSection = ({ children, headerTitle, subtitle, buttonTitle, linkTo, featuredImage }) => {
	return (
		<Wrapper>
			<HeaderWrapper
				title="Wholesale helium from Tex-Air Gas fuels David Blaine’s balloon ascension across"
				style={{
        	backgroundImage: `url(${!featuredImage ? null : featuredImage.childImageSharp ? featuredImage.childImageSharp.fluid.src : featuredImage})`
        }}>
				<SiteContent center>
					<TextBlock
	          h2
	          center
	          titleColor='white'
	          color='white'
	          headerText={headerTitle}
	          bodyText={subtitle}
	        />
	        <VideoWrapper>
	        	<Video
			        videoSrcURL="https://www.youtube.com/embed/u84hYvlxXLE"
			        videoTitle="Official Music Video on YouTube"
			      />
			      <Video
			        videoSrcURL="https://www.youtube.com/embed/FHtvDA0W34I"
			        videoTitle="Official Music Video on YouTube"
			      />
			    </VideoWrapper>
				</SiteContent>
			</HeaderWrapper>
		</Wrapper>
	);
}

InnovationsSection.propTypes 	  = propTypes;
InnovationsSection.defaultProps  = defaultProps;

export default InnovationsSection;