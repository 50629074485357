import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import logo from '../images/tex-air-gas-logo.svg';

import { ButtonPrimary } from '../components/Buttons';

const Nav = styled.div`
  max-width: 1800px;
  margin: 0 auto;
  position: absolute;
  z-index: 99;
  left: 20px;
  right: 20px;
  top: 20px;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;

  @media only screen and (max-width: 1023px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 580px) {
    padding: 0 10px;
  }
`;

const LogoImage = styled.img`
  width: 280px;

  @media only screen and (max-width: 580px) {
    width: 220px;
  }
`;

const NavBarLogo = styled.div`
  padding-right: 20px;
`;

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <Nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <NavWrapper>
          <NavBarLogo className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <LogoImage src={logo} alt="tex-air gas logo of atoms and ions representing industrial gas" title="Tex-Air Gas logo of atoms and ions representing industrial gas"/>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}>
              <span />
              <span />
              <span />
            </div>
          </NavBarLogo>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <ButtonPrimary
              navButton
              linkTo='#contact'>Contact Us</ButtonPrimary>
          </div>
        </NavWrapper>
      </Nav>
    );
  }
}

export default Navbar;
