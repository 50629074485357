import { Link }   from "gatsby";
import styled     from 'styled-components';
import PropTypes  from "prop-types";
import React      	from "react";
import { Colors } 		from '../Layouts/Colors';

const propTypes = {
	color  : PropTypes.string,
	linkTo : PropTypes.string,
},
defaultProps = {
	color  : Colors.Yellow,
	linkTo : '/#'
}

const ButtonComponent = styled.div`
	border-radius: 40px;
	height: 40px;
	min-width: 121px;
	max-width: 400px;
	background-color: ${({ inverted }) => inverted ? Colors.White : Colors.Yellow };
	color: ${({ inverted }) => inverted ? Colors.Primary : Colors.White };
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-start;
	padding-left: 23px;
	padding-right: 23px;
	text-align: center;
	transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;

	&:hover {
		background-color: ${({ inverted }) => inverted ? Colors.Yellow : Colors.White };
		color: ${({ inverted }) => inverted ? Colors.White : Colors.Yellow };
	}

	${({ navButton }) => navButton &&`
		@media only screen and (max-width: 780px) {
	  }

	  @media only screen and (max-width: 640px) {
	  	min-width: 140px;
	    height: 35px;
	    padding-left: 18px;
			padding-right: 18px;
	  }

	  @media only screen and (max-width: 530px) {
	  }
	`}
`;

const ButtonCopy = styled.span`
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 1.2;
	font-family: termina, sans-serif;
	font-weight: 700;
	text-transform: uppercase;

	${({ navButton }) => navButton &&`
		@media only screen and (max-width: 780px) {
	  }

	  @media only screen and (max-width: 640px) {
			font-size: 12px;
			letter-spacing: .1px;
	  }
 `}
`;

const LinkComponent = styled(Link)`
  display: ${({ flexBox }) => flexBox ? 'flex' : 'inline-block'};
	justify-content: ${({ flexBox }) => flexBox ? 'center' : null};

`;

	// border: 2px solid ${({ inverted, disabled, color }) => _borderColor(inverted, disabled, color) };
	// background-color: ${({ inverted, color }) => color ? color : inverted ? Colors.White : Colors.Black };
	// color: ${({ inverted, color }) => color ? color : inverted ? Colors.Black : Colors.White };


// function _borderColor (inverted, disabled, color) {
// 	if (disabled || (disabled && inverted)) {
// 		return '#D3D3D3';
// 	} else if (inverted) {
// 		return color;
// 	} else {
// 		return color;
// 	}
// }

// function _backgroundColor (inverted, disabled, color) { 
// 	if (inverted) {
// 		return Colors.White; 
// 	} else if (disabled) {
// 		return '#D3D3D3';
// 	} else {
// 		return color;
// 	}
// }

// function _textColor (inverted, disabled) {
// 	if (inverted) {
// 		return Colors.Black;
// 	} else if (disabled) {
// 		return "#A8A8A8";
// 	} else {
// 		return Colors.White;
// 	}
// }

/**
A primary button that matches the brand.
*/
const ButtonPrimary = ({ ...props }) => {
	return (
		<LinkComponent 
			flexBox={props.flexBox}
			to={props.linkTo}>
			<ButtonComponent
				navButton={props.navButton}
				inverted={props.inverted} 
				disabled={props.disabled} 
				color={props.color}
				textColor={props.textColor}>
				<ButtonCopy navButton={props.navButton}>{props.children}</ButtonCopy>
			</ButtonComponent>
		</LinkComponent>
	);
};


ButtonPrimary.propTypes 	  = propTypes;
ButtonPrimary.defaultProps  = defaultProps;

export default ButtonPrimary;