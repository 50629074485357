import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header,
				 Body } from './Typography';
import { NewLink } from './Buttons';
import { SiteContent } from './Layouts';

// import homeHero from '../../img/magic-pie-copy-home-hero.svg';
import waves from '../images/texAirGas-waves.svg';

const propTypes = {
	linkTo: PropTypes.string,
},
defaultProps = {
	linkTo: "#"
}

const Wrapper = styled.div``;

const Divider = styled.img`
	width: 100%;
	margin-top: -178px;

	@media only screen and (max-width: 580px) {
		min-width:  100.1%;
	}
`;

const HeaderWrapper = styled.div`
  min-height: 500px;
  height: 80vh;
  background-color: ${({ inverted }) => inverted ? 'black' : '#f2f2f2' };
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding: 180px 0 180px 0;

	::selection {
	  background: #000;
	  color: #f2f2f2;
	};

  @media only screen and (max-width: 780px) {
  	height: 100%;
	}

	@media only screen and (max-width: 580px) {
	}

	@media only screen and (max-width: 400px) {
	}
`;

const HomePageHero = ({ children, headerTitle, subtitle, buttonTitle, linkTo, featuredImage }) => {
	return (
		<Wrapper>
			<HeaderWrapper
				title="Wholesale helium distribution trucks from Tex-Air Gas"
				style={{
        	backgroundImage: `url(${!featuredImage ? null : featuredImage.childImageSharp ? featuredImage.childImageSharp.fluid.src : featuredImage})`
        }}>
				<SiteContent center>
					<Header h1 color='white'>
						{headerTitle}
		      </Header>
		      <Body 
		      	paddingBottom
		      	color="white">
		      	{subtitle}
		      </Body>
		      <NewLink
		      	down 
		      	inverted 
		      	linkTo={linkTo}>{buttonTitle}</NewLink>
				</SiteContent>
			</HeaderWrapper>
			<Divider src={waves} />
		</Wrapper>
	);
}

HomePageHero.propTypes 	  = propTypes;
HomePageHero.defaultProps  = defaultProps;

export default HomePageHero;