import React          from "react";
import PropTypes 			from 'prop-types';
import styled         from 'styled-components';
import { Colors } 		from '../Layouts/Colors';


const propTypes = {
	color  : PropTypes.string,
},
defaultProps = {
	color  : Colors.Primary,
}

const Wrapper = styled.div``;

const HeaderH1 = styled.h1`
  font-family: termina, sans-serif;
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	font-size: 62px;
	margin-bottom: ${({ noMargin }) => noMargin ? 0 : '30px'};
	color: ${({ color }) => color};
	line-height: 72px;
	padding: ${({ noPadding }) => noPadding ? 0 : 'inherit'};
  white-space: pre-line;

	::selection {
	  background: #000;
	  color: white;
	};
  @media only screen and (max-width: 780px) {
		font-size: 54px;
		line-height: 60px;
	}

	@media only screen and (max-width: 580px) {
		font-size: 48px;
		line-height: 52px;
	}

	@media only screen and (max-width: 400px) {
		font-size: 42px;
		line-height: 42px;
	}

`;

const HeaderH2 = styled.h2`
  font-family: termina, sans-serif;
  font-weight: bold;
	font-size: 38px;
	line-height: 38px;
	letter-spacing: -1.25px;
	margin-bottom: ${({ noMargin }) => noMargin ? 0 : '26px'};
	color: ${({color}) => color};
	padding: ${({noPadding}) => noPadding ? 0 : 'inherit'};
	white-space: pre-line;

	::selection {
	  background: #000;
	  color: white;
	}
	@media only screen and (max-width: 780px) {
		font-size: 38px;
		letter-spacing: -1px;
		line-height: 42px;
	}

	@media only screen and (max-width: 580px) {
		font-size: 36px;
		line-height: 40px;
	}
`;

const HeaderH3 = styled.h3`
  font-family: termina, sans-serif;
  font-weight: bold;
	font-size: 32px;
	letter-spacing: -1px;
	margin-bottom: ${({ noMargin }) => noMargin ? 0 : '9px'};
	color: ${({color}) => color};
	padding: ${({noPadding}) => noPadding ? 0 : 'inherit'};
	white-space: pre-line;

	::selection {
	  background: #000;
	  color: white;
	}

	@media only screen and (max-width: 780px) {
		font-size: 28px;
		letter-spacing: 0;
		line-height: 32px;
	}

	@media only screen and (max-width: 580px) {
		font-size: 24px;
		line-height: 28px;
	}
`;

const HeaderH4 = styled.h4`
  font-family: termina, sans-serif;
  font-weight: bold;
	font-size: 28px;
	line-height: 33px;
	margin-bottom: ${({ noMargin }) => noMargin ? 0 : '9px'};
	color: ${({color}) => color};
	padding: ${({noPadding}) => noPadding ? 0 : 'inherit'};
	white-space: pre-line;

	::selection {
	  background: #000;
	  color: white;
	}

	@media only screen and (max-width: 780px) {
		font-size: 24px;
		letter-spacing: 0;
		line-height: 28px;
	}

	@media only screen and (max-width: 580px) {
		font-size: 20px;
		line-height: 24px;
	}
`;

const HeaderH5 = styled.h5`
  font-family: termina, sans-serif;
  font-weight: bold;
	font-size: 21px;
	line-height: 33px;
	margin-bottom: ${({ noMargin }) => noMargin ? 0 : '9px'};
	color: ${({color}) => color};
	padding: ${({noPadding}) => noPadding ? 0 : 'inherit'};
	white-space: pre-line;

	::selection {
	  background: #000;
	  color: white;
	}

	@media only screen and (max-width: 780px) {
		font-size: 20px;
		letter-spacing: 0;
		line-height: 26px;
	}

	@media only screen and (max-width: 580px) {
		font-size: 18px;
		line-height: 24px;
	}
`;

const Header = ({ children, color, noMargin, noPadding, h1, h2, h3, h4, h5 }) => {

	return (
		<Wrapper>
			{ h1 &&
			<HeaderH1 noMargin={noMargin} color={color}>{children}</HeaderH1> }
			{ h2 &&
			<HeaderH2 noMargin={noMargin} color={color}>{children}</HeaderH2> }
			{ h3 &&
			<HeaderH3 noMargin={noMargin} color={color}>{children}</HeaderH3> }
			{ h4 &&
			<HeaderH4 noMargin={noMargin} color={color}>{children}</HeaderH4> }
			{ h5 &&
			<HeaderH5 noMargin={noMargin} color={color}>{children}</HeaderH5> }
		</Wrapper>
	);
}

Header.propTypes 	  = propTypes;
Header.defaultProps  = defaultProps;

export default Header;