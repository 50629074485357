import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
},
defaultProps = {
}

const Wrapper = styled.div`
  display: flex;
`;

const VideoWrapper = styled.iframe`
	width: 110%;
  height: 180px;
  margin-bottom: 0;
  display: flex;
  @media only screen and (max-width: 780px) {
	  margin: 10px;
	}
`;

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <Wrapper className="video">
    <VideoWrapper
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </Wrapper>
)

Video.propTypes 	  = propTypes;
Video.defaultProps  = defaultProps;

export default Video;