import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonPrimary } from '../Buttons';
import { Header,
				 Subheader,
         Body } from '../Typography';      

const propTypes = {
	maxWidth: PropTypes.string,
	marginBottom: PropTypes.string,
},
defaultProps = {
	maxWidth: '1400px',
};

const TextBlockWrapper = styled.div``;
const Wrapper = styled.div`
	text-align: ${({ center }) => center ? 'center' : null };
	margin-bottom: ${({ marginBottom, buttonTitle }) => 
		marginBottom ? marginBottom : buttonTitle ? '24px' : null};
`;

const TextBlock = ({ h1, h2, h3, subheader, center, marginBottom, titleColor, color, maxWidth, headerText, bodyText, linkTo, buttonTitle }) => {

	return (
		<TextBlockWrapper maxWidth={maxWidth}>
			<Wrapper 
				marginBottom={marginBottom}
				buttonTitle={buttonTitle}
				center={center}>
				{ subheader &&
				  <Subheader color={color}>{subheader}</Subheader>
				}
				{ h1 &&
				  <Header color={titleColor} h1>{headerText}</Header>
			 	}
			 	{ h2 &&
				  <Header color={titleColor} h2>{headerText}</Header>
			 	}
			 	{ h3 &&
				  <Header color={color} h3>{headerText}</Header>
			 	}
			 	{ bodyText &&
				  <Body color={color}>{bodyText}</Body>
				}
			 </Wrapper>
		 	{ buttonTitle &&
		 		<ButtonPrimary 
	      	flexBox
	      	linkTo={linkTo}>{buttonTitle}</ButtonPrimary>
		 	}
	  </TextBlockWrapper>
  );
}

TextBlock.propTypes 	  = propTypes;
TextBlock.defaultProps  = defaultProps;

export default TextBlock;