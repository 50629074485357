import React from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { Header,
         Body } from './Typography';   

import data from '../data/data';
import { Colors } from './Layouts';

import 'swiper/css/swiper.css';

const TimelineLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  align-items: center;
`;

const Circle = styled.div`
  background-color: ${Colors.Yellow};
  width: ${({ index }) => index > 0 ? '8px' : '10px'};
  height: ${({ index }) => index > 0 ? '8px' : '10px'};
  border-radius: 50%;
`;

const Line = styled.div`
  width: 98%;
  height: 1.5px;
  background-color: black;
  opacity: .15;
`;

const CardWrapper = styled.div`
  padding-left: ${({ index }) => index > 0 ? null : '10%'};
  padding-right: ${({ index, data }) => index === data.length - 1 ? '10%' : null};
  height: 400px;
  width: 400px;

  @media only screen and (max-width: 640px) {
    padding-left: 36px;
  }
`;

const ContentWrapper = styled.div`
  margin-right: 60px;
`;

const Wrapper = styled.div`
  min-height: 400px;
  padding-bottom: 180px;

  @media only screen and (max-width: 1200px) {
  }

  @media only screen and (max-width: 840px) {
  }

  @media only screen and (max-width: 780px) {
  }

  @media only screen and (max-width: 640px) {
  }

  @media only screen and (max-width: 530px) {
  }
`;

const Timeline = ({data}) => {

  const params = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    mousewheel: false,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next.customButtonNext',
      prevEl: '.swiper-button-prev.customButtonPrev'
    }
  }
  
  // let incomingData = data.timeline.details;

  return (
    <Wrapper>
      <Swiper {...params}>

        {data.map((data, index) => {
          return (
            <CardWrapper key={index} index={index} data={data}>
              <TimelineLineWrapper>
                <Circle index={index}/>
                <Line/>
              </TimelineLineWrapper>
              <ContentWrapper>
                <Header h5>{data.year}</Header> 
                <Body>{data.body}</Body> 
              </ContentWrapper>
            </CardWrapper>
          );
        })}
   
      </Swiper>
    </Wrapper>
  );
};

export default Timeline;