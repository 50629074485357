import React          from "react";
import PropTypes 			from 'prop-types';
import styled         from 'styled-components';
import { Colors } 		from '../Layouts/Colors';


const propTypes = {
	color  : PropTypes.string,
},
defaultProps = {
	color  : Colors.BabyBlue,
}


const SubheaderWrapper = styled.h6`
  font-family: termina, sans-serif;
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	font-size: 19px;
	margin-bottom: 3px;
	color: ${({color}) => color};
	padding: ${({noPadding}) => noPadding ? 0 : 'inherit'};
  white-space: pre-line;

	::selection {
	  background: #000;
	  color: white;
	};
  @media only screen and (max-width: 780px) {
	}

	@media only screen and (max-width: 580px) {
	}

	@media only screen and (max-width: 400px) {
	}

`;


const Subheader = ({ children, color, noPadding}) => {

	return (
			<SubheaderWrapper color={color}>{children}</SubheaderWrapper> 
	);
}

Subheader.propTypes 	  = propTypes;
Subheader.defaultProps  = defaultProps;

export default Subheader;